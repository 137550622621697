<template>
  <content-with-sidebar class="blog-wrapper">
    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col v-for="blog in blogList" :key="blog.img" md="6">
        <b-card tag="article" no-body>
          <b-link
            :to="{
              name: 'news-waste-detail',
              params: { id: blog.id },
            }"
          >
            <b-img
              :src="blog.img"
              :alt="blog.img.slice(5)"
              class="card-img-top"
            />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link
                :to="{
                  name: 'news-waste-detail',
                  params: { id: blog.id },
                }"
                class="blog-title-truncate text-body-heading"
              >
                {{ blog.title }}
              </b-link>
            </b-card-title>
            <div class="my-1 py-25">
              <b-link v-for="(tag, index) in blog.tags" :key="index">
                <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>
            <b-card-text class="blog-content-truncate">
              {{ blog.excerpt }}
            </b-card-text>
            <hr />
            <div class="d-flex justify-content-between align-items-center">
              <b-link
                :to="{
                  name: 'news-waste-detail',
                  params: { id: blog.id },
                }"
                class="font-weight-bold"
              >
                Read More
              </b-link>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <!-- recent posts -->
      <div class="blog-recent-posts">
        <h6 class="section-label mb-75">Recent Posts</h6>
        <b-media
          v-for="(recentpost, index) in blogSidebar.recentPosts"
          :key="recentpost.img"
          no-body
          :class="index ? 'mt-2' : ''"
        >
          <b-media-aside class="mr-2">
            <b-link
              :to="{
                name: 'news-waste-detail',
                params: { id: recentpost.id },
              }"
            >
              <b-img
                :src="recentpost.img"
                :alt="recentpost.img.slice(6)"
                width="100"
                rounded
                height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link
                :to="{
                  name: 'news-waste-detail',
                  params: { id: recentpost.id },
                }"
                class="text-body-heading"
              >
                {{ recentpost.title }}
              </b-link>
            </h6>
            <span class="text-muted mb-0">
              {{ recentpost.createdTime }}
            </span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->
    </div>
    <!--/ sidebar -->
  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import ContentWithSidebar from "@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    ContentWithSidebar,
  },
  data() {
    return {
      search_query: "",
      blogList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: 140,
    };
  },
  created() {
    this.$http.get("/blog/list/data").then((res) => {
      this.blogList = res.data;
    });
    this.$http.get("/blog/list/data/sidebar").then((res) => {
      this.blogSidebar = res.data;
    });
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === "Quote") return "light-info";
      if (tag === "Gaming") return "light-danger";
      if (tag === "Fashion") return "light-primary";
      if (tag === "Video") return "light-warning";
      if (tag === "Food") return "light-success";
      return "light-primary";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-blog.scss";
</style>
