var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-with-sidebar',{staticClass:"blog-wrapper"},[_c('b-row',{staticClass:"blog-list-wrapper"},_vm._l((_vm.blogList),function(blog){return _c('b-col',{key:blog.img,attrs:{"md":"6"}},[_c('b-card',{attrs:{"tag":"article","no-body":""}},[_c('b-link',{attrs:{"to":{
            name: 'news-waste-detail',
            params: { id: blog.id },
          }}},[_c('b-img',{staticClass:"card-img-top",attrs:{"src":blog.img,"alt":blog.img.slice(5)}})],1),_c('b-card-body',[_c('b-card-title',[_c('b-link',{staticClass:"blog-title-truncate text-body-heading",attrs:{"to":{
                name: 'news-waste-detail',
                params: { id: blog.id },
              }}},[_vm._v(" "+_vm._s(blog.title)+" ")])],1),_c('div',{staticClass:"my-1 py-25"},_vm._l((blog.tags),function(tag,index){return _c('b-link',{key:index},[_c('b-badge',{staticClass:"mr-75",attrs:{"pill":"","variant":_vm.tagsColor(tag)}},[_vm._v(" "+_vm._s(tag)+" ")])],1)}),1),_c('b-card-text',{staticClass:"blog-content-truncate"},[_vm._v(" "+_vm._s(blog.excerpt)+" ")]),_c('hr'),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('b-link',{staticClass:"font-weight-bold",attrs:{"to":{
                name: 'news-waste-detail',
                params: { id: blog.id },
              }}},[_vm._v(" Read More ")])],1)],1)],1)],1)}),1),_c('div',{staticClass:"blog-sidebar py-2 py-lg-0",attrs:{"slot":"sidebar"},slot:"sidebar"},[_c('div',{staticClass:"blog-recent-posts"},[_c('h6',{staticClass:"section-label mb-75"},[_vm._v("Recent Posts")]),_vm._l((_vm.blogSidebar.recentPosts),function(recentpost,index){return _c('b-media',{key:recentpost.img,class:index ? 'mt-2' : '',attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-link',{attrs:{"to":{
              name: 'news-waste-detail',
              params: { id: recentpost.id },
            }}},[_c('b-img',{attrs:{"src":recentpost.img,"alt":recentpost.img.slice(6),"width":"100","rounded":"","height":"70"}})],1)],1),_c('b-media-body',[_c('h6',{staticClass:"blog-recent-post-title"},[_c('b-link',{staticClass:"text-body-heading",attrs:{"to":{
                name: 'news-waste-detail',
                params: { id: recentpost.id },
              }}},[_vm._v(" "+_vm._s(recentpost.title)+" ")])],1),_c('span',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(recentpost.createdTime)+" ")])])],1)})],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }